import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cb510aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat" }
const _hoisted_2 = {
  ref: "innerRef",
  class: "scorllBox"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"margin-left":"4px","font-size":"12px"} }
const _hoisted_6 = { class: "btnBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item"
        }, [
          (item.data.user)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.userId===item.data.user.userId ? 'topByUser' : 'top')
                }, [
                  _createElementVNode("div", {
                    style: {"cursor":"pointer"},
                    onClick: _withModifiers(($event: any) => (_ctx.goAuthor(item.data.user.userId)), ["stop"]),
                    class: "avaImg"
                  }, [
                    _createVNode(_component_el_avatar, {
                      size: 30,
                      src: item.data.user.headImgUrl
                    }, null, 8, ["src"])
                  ], 8, _hoisted_3),
                  _createElementVNode("span", {
                    onClick: _withModifiers(($event: any) => (_ctx.goAuthor(item.data.user.userId)), ["stop"]),
                    style: {"margin-left":"4px","margin-right":"4px","cursor":"pointer"}
                  }, _toDisplayString(item.data.user.nickName), 9, _hoisted_4),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(item.time), 1)
                ], 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.userId===item.data.user.userId ? 'msgByUser' : 'msg')
                }, _toDisplayString(item.data.message), 3)
              ], 64))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 512),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.messageNum)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            onClick: _ctx.goNewMessageFun,
            class: _normalizeClass(_ctx.isScroll ? 'showBtn' : 'noShowBtn'),
            type: "primary",
            round: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`未读消息${_ctx.messageNum}`), 1)
            ]),
            _: 1
          }, 8, ["onClick", "class"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_input, {
      onKeyup: _withKeys(_ctx.sendBtnFun, ["enter","native"]),
      clearable: "",
      modelValue: _ctx.msg,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.msg) = $event)),
      placeholder: "发个消息",
      class: "input-with-select"
    }, {
      append: _withCtx(() => [
        _createVNode(_component_el_button, {
          onClick: _withModifiers(_ctx.sendBtnFun, ["stop"]),
          class: "sendBtn",
          icon: _ctx.Promotion
        }, null, 8, ["onClick", "icon"])
      ]),
      _: 1
    }, 8, ["onKeyup", "modelValue"])
  ]))
}