
import { defineComponent, onMounted, computed, ref, reactive, watchEffect } from 'vue'
import Search from '../Serarch/Search.vue'
import NavMenu from './NavMenu.vue'
import HelloWorld from '../HelloWorld.vue'
import Chat from '../../views/chat/Chat.vue'
import { useRouter } from 'vue-router'
import { Edit } from '@element-plus/icons-vue'
import { getCategorys } from '../../service/article/index'
import { logout } from '../../service/user/user'
import localCache from '../../utils/cache'
import { useStore } from 'vuex'
import { NavMenuEmitData } from './types'

import message from '../../utils/message'

import { MySocket } from '../../public/mySocket'

export default defineComponent({
  components: {
    Edit,
    Search,
    NavMenu,
    HelloWorld,
    Chat
  },
  emits: ['chilkEmit'],
  setup(props, content) {
    const router = useRouter()
    const store = useStore()
    let phoneMessageDialogVisible = ref(false)

    let categorysArray = ref([])
    async function categorys() {
      const data = await getCategorys()
      if (data.success) {
        categorysArray.value = data.data
      } else {
        message.warning(data.data)
      }
    }
    categorys()
    /**
     * type:通过什么类型请求数据
     * name:在URL地址栏中向用户展示查询条件
     * id:请求数据的参数
     */
    const articleListByData = (type: string, name: string, id: string) => {
      router.push({
        name: 'article',
        path: '/article',
        query: {
          name,
          id
        },
        params: {
          type,
        },
      })
      emitFun(id)
    }
    /**
     * 移动端导航的传值,并且要调用nav的articleListByData
     */
    const NavMenuSelect = (data: NavMenuEmitData) => {
      articleListByData(data.type, data.name, data.id)
    }

    /**
     * data请求数据的参数
     */
    const emitFun = (data: string) => {
      content.emit('chilkEmit', { data })
    }
    /**
     * 因为搜索组件是Articlelist的孙组件,需要三个组件来传值
     */
    const chilkSearch = (value: string) => {
      emitFun(value)
    }
    function userInfo() {
      console.log(store.state.login.token)
      if (store.state.login.token || localCache.getCache('token')) {
        store.dispatch('user/getUserInfoAction')
      }
    }
    onMounted(() => {
      userInfo()
    })
    const userName = computed(() => {
      return store.state.user.userInfo.nickName
    })
    async function out() {
      const data = await logout()
      if (data.success) {
        localCache.deleteCache('token')
        store.commit('user/changeUserInfo', {})
        router.push('/')
        message.success(data.data)
        MySocket.finish()
      } else {
        message.warning(data.data)
      }
    }
    const outLogin = () => {
      out()
    }
    return {
      userName,
      outLogin,
      categorysArray,
      articleListByData,
      chilkSearch,
      NavMenuSelect,
      phoneMessageDialogVisible
    }
  },
})
