
import { defineComponent, ref } from 'vue'
import { getCategorys } from '../../service/article/index'
import { Expand } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import message from '../../utils/message'

export default defineComponent({
  name: 'NavMenu',
  components: {
    Expand,
  },
  emits: ['NavMenuSelectEmit'],
  setup(props, content) {
    const router = useRouter()
    const drawer2 = ref(false)
    let categorysArray = ref([])
    //获取分类
    async function categorys() {
      const data = await getCategorys()
      if (data.success) {
        categorysArray.value = data.data
      } else {
        message.warning(data.data)
      }
    }
    categorys()
    const articleListByData = (type: string, name: string, id: string) => {
      content.emit('NavMenuSelectEmit', { type, name, id })
      drawer2.value = false
    }
    const getHome = () => {
      router.push('/')
      drawer2.value = false
    }
    return {
      drawer2,
      categorysArray,
      articleListByData,
      getHome,
    }
  },
})
