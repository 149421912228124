
import { defineComponent } from 'vue'
import {test} from '../utils/test'

export default defineComponent({
  name: 'HelloWorld',
  setup(props,content){
    test()
  }
})
