
import { defineComponent, computed, ref, nextTick, onMounted } from 'vue'

import { MySocket } from '../../public/mySocket'
import { WsMsgType } from '../../public/type'

import { Promotion } from '@element-plus/icons-vue'

import { useStore } from '../../store/'
import { useRouter } from 'vue-router'

import { formatUtcAllString } from '../../utils/date'
import message from '../../utils/message'

import {GetMsg} from '../../service/word/word'

export default defineComponent({
  name: 'LoginAccount',
  setup(props, content) {
    let msg = ref('')
    let messageList = ref<any>([])
    const innerRef = ref<HTMLDivElement>()
    const store=useStore()
    const router = useRouter()
    let userId=computed(()=>{
      return store.state.user.userInfo.userId
    })

    let scrollLen=0
    let messageNum=ref(0)
    let isScroll = ref(false)
    let isMouseover=(false)

    
    const init=()=>{
      scrollLen=0
      messageNum.value=0
      isScroll.value=false
    }
    //获取历史世界消息
    async function getMsgFun() {
      const data=await GetMsg()
      if(data.success){
        messageList.value=data.data
        nextTick(()=>{
          innerRef.value!.scrollTop=innerRef.value!.scrollHeight
        })
      }else{
        message.error(data.data)
      }
    }
    onMounted(()=>{
      getMsgFun()
      //监听pc端鼠标经过,当用户鼠标经过并且又监听到滚动条滚动了,则视为用户在翻记录
      innerRef.value!.addEventListener('mouseover',(e)=>{
      isMouseover=true
      })
      //监听手机端滚动,只要滚动就代表用户已经在翻记录了(滚动条也会跟着滚动)
      innerRef.value!.addEventListener('scroll',(e)=>{
      isMouseover=true
      })
      //滚动条滚动了
      innerRef.value!.addEventListener('scroll',(e)=>{
        if(isMouseover){
          if(innerRef.value!.scrollHeight-innerRef.value!.scrollTop<300){
            init()
          }else {
            isScroll.value=true
          }
        }
      })
    })
    //回到最新的消息列表
    const goNewMessageFun=()=>{
      innerRef.value!.scrollTop=scrollLen
      init()
    }
    const callback = (data: any) => {
      if (data.event === WsMsgType.BROADCAST_MESSAGE) {
        messageList.value.push(data)
        nextTick(()=>{
          if(!isScroll.value){
            messageNum.value=0
            if(innerRef.value){
              innerRef.value!.scrollTop=innerRef.value!.scrollHeight
            }
          }else{
            messageNum.value++
            scrollLen=innerRef.value!.scrollHeight
          }
        })
      }
    }
    onMounted(() => {
      MySocket.funList.push(callback)
    })
    const sendBtnFun = () => {
      if (!store.state.user.userInfo.userId) {
        router.push('/login')
        return
      }
      if (!msg.value) {
        return message.warning('请输入消息')
      }
      init()
      MySocket.sendMessage(msg.value)
      msg.value = ''
    }
    const goAuthor=(userid:string)=>{
      router.push({
        name:'usermenu',
        path:'/usermenu',
        params:{
          userid
        }
      })
    }
    return {
      msg,
      sendBtnFun,
      Promotion,
      messageList,
      formatUtcAllString,
      innerRef,
      userId,
      isScroll,
      goNewMessageFun,
      messageNum,
      goAuthor
    }
  },
})
