
import { defineComponent, ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Serarch',
  emits: ['chilkSearchEmit', 'isOkSearchEmit'],
  setup(props, content) {
    let searchValue = ref<string>('')
    const router = useRouter()

    const showList=()=>{

    }
    const getSearchPage = (type: string, name: string, id: string) => {
      router.push({
        path: 'article',
        name: 'article',
        query: {
          name,
        },
        params: {
          type,
        },
      })
      searchValue.value = ''
      content.emit('chilkSearchEmit', name)
      content.emit('isOkSearchEmit')
    }
    return {
      Search,
      searchValue,
      getSearchPage,
      showList
    }
  },
})
